import { Link } from 'react-router-dom'
import '../Assets/Css/Components/Services.css'
import {ReactComponent as Location} from '../Assets/Icons/location.svg'
import ServicesPreview from '../Assets/Images/Branding/servicesApp.png'
import Design from '../Assets/Images/Services/design.png'
import DevIT from '../Assets/Images/Services/dev.png'
import CManage from '../Assets/Images/Services/cm.png'
import Noa from '../Assets/Images/Partners/TrustUs/noa.png'
import Lonni from '../Assets/Images/Partners/TrustUs/lonni.png'
import Evan from '../Assets/Images/Partners/TrustUs/evan.png'
import Footer from './Partials/Footer'


const Services = ({langs, language, theme}) => {

  return ( 
      <div className='ContentApp ServicesApp'>
        

        <div className="servicesHdr">
          <div className="svrTxts">
            <h1>
              {langs.servicesApp.h1Text[language]}
            </h1>
            <h3>
              {langs.servicesApp.h3Text[language]}
            </h3>
          <Link to="/contact">
             {langs.servicesApp.buttonText[language]}
          </Link>
          </div>

          <div className="svrImg">
            <img src={ServicesPreview} alt="Unovates" />
            <div className="lct">
            <Location/> <span>Paris, France</span>
            </div>
          </div>
        </div>




        <div className="servicesList">
          <div className="svLsTop">
            <h1>
            {langs.servicesApp.servicesPart.h1Text[language]}
            </h1>
            <h3>
            {langs.servicesApp.servicesPart.h3Text[language]}
            </h3>
          </div>

          <div className="svList">
            <div className="svl">
              <img src={Design} alt="Design"/>
              <h1>
              {langs.servicesApp.servicesPart.design[language]}
              </h1>
            </div>
            <div className="svl">
              <img src={DevIT} alt="DevIT"/>
              <h1>
              {langs.servicesApp.servicesPart.developement[language]}
              </h1>
            </div>
            <div className="svl">
              <img src={CManage} alt="Community Management"/>
              <h1>
              {langs.servicesApp.servicesPart.cManage[language]}
              </h1>
            </div>
          </div>
        </div>



        <div className="creatorsList">
          <div className="ctLsTop">
            <h1>
            {langs.servicesApp.creatorsPart.h1Text[language]}
            </h1>
            <h3>
            {langs.servicesApp.creatorsPart.h3Text[language]}
            </h3>
          </div>

          <div className="ctsList">
            <div className="cts">
              <img src={Noa} alt="Noa Dorian" />
            </div>
            <div className="cts">
              <img src={Lonni} alt="Lonni" />
            </div>
            <div className="cts">
              <img src={Evan} alt="Evan" />
            </div>
          </div>
        </div>


        {
       // <div style={{height: "500px"}}></div>
      }


        <Footer theme={theme} language={language} langs={langs}/>

      </div>
  )

}

export default Services