import { useEffect, useState } from 'react'
import '../Assets/Css/Components/Blog.css'
import axios from 'axios'
import moment from "moment"
import {ReactComponent as NewsPaper} from '../Assets/Icons/newspaper.svg'
import Footer from './Partials/Footer'


const Blog = ({api, langs, language, theme}) => {

  const [articles, setArticles] = useState([])
  const [staffs, setStaffs] = useState([])


  async function fetchAllStaff() {
    let res = await axios({
      method: "get",
      withCredentials: true,
      url: api+"api/staff",
    })
    if(res.data.status !== 202) return;
    setStaffs(res.data.allStaff)
  }

  function formatDate(timestamp) {
    const aujourdhui = moment();
    const date = moment(timestamp).locale('de');
  
    // Vérifier si c'est aujourd'hui
    if (date.isSame(aujourdhui, 'day')) {
      return `${language === "fr" ? "aujourd'hui": "today"}`
    }
  
    // Vérifier si c'est hier
    if (date.isSame(aujourdhui.clone().subtract(1, 'day'), 'day')) {
      return `${language === "fr" ? "hier": "yesterday"}`
    } 
    
  
    // Si ce n'est ni aujourd'hui ni hier, formatter en "le JJ mois"
    return `${language === "fr" ? "Le": ""} ${date.format("DD/MM")}`;
  }

  async function fetchArticles() {
    let res = await axios({
      method: "get",
      withCredentials: true,
      url: api+"api/blog",
    })

    if(res.data.status !== 202) return;
    setArticles(res.data.articles)
  }

  useEffect(() => {
    fetchAllStaff()
    fetchArticles()
  }, [])





  const ArticlesComponent = ({ articles }) => {

    function splitArticles(array) {
     // console.log(array)
      let filtered = [];
      let index = 0;
    
      array.forEach((arr, i) => {
        if (index === 0) {
          filtered.push(arr);
          index++;
        } else if (index === 1) {
          if (array[i + 1]) {
            filtered.push([arr, array[i + 1]]);
            index += 2;
          }
        } else if (index === 3) {
          index = 0;
        } 
      });
    
      return filtered;
    }


    let allArticles = splitArticles(articles)

    console.log(allArticles)


    return (
      <div className="allArticles">
        {allArticles.map(art => {
          if(!art.length) {
            return (
              <div className="article">
                <div className="content">
                <div className="image">
                  <img src={art.imageLink} alt="" />
                </div>
                <div className="texts">
                  <h1>{art.title}</h1>
                  <h2>{art.secondTitle}</h2>
                  <p>{art.description}</p>
                </div>
                </div>
                <div className="date">
                      <NewsPaper/>
                      <div className="dateTxt">
                      {language === "fr" ? 'Posté' : 'Posted'} {formatDate(parseInt(art.created))} {language === "fr" ? 'par' : 'by'} 
                      <h4>{staffs.find(u => u.userId === art.authorId)?.name || "Unknown"} <img className='authorIcon' src={staffs.find(u => u.userId === art.authorId)?.avatarLink} alt="" /></h4>
                      </div>
                      
                </div>
              </div>
            )
          } else {
            return (
              <div className="twoArticles">
                {art.map(secArt => {
                  return (
                    <div className="article column">
                    <div className="content">
                    <div className="image">
                      <img src={secArt.imageLink} alt="" />
                    </div>
                    <div className="texts">
                      <h1>{secArt.title}</h1>
                      <h2>{secArt.secondTitle}</h2>
                      <p>{secArt.description}</p>
                    </div>
                    </div>
                    <div className="date">
                    <NewsPaper/>
                      <div className="dateTxt">
                      {language === "fr" ? 'Posté' : 'Posted'} {formatDate(parseInt(secArt.created))} {language === "fr" ? 'par' : 'by'} 
                      <h4>{staffs.find(u => u.userId === secArt.authorId)?.name || "Unknown"} <img className='authorIcon' src={staffs.find(u => u.userId === secArt.authorId)?.avatarLink} alt="" /></h4>
                      </div>
                      </div>
                  
                  </div>
                  )
                })}
              </div>
            )
          }
        })}
      </div>
    )

  }

  return ( 
      <div className='ContentApp BlogApp'>

        <div className="blogHdr">
          <h1>{langs.blogsApp.h1Text[language]}</h1>
          <h2>{langs.blogsApp.h2Text[language]}</h2>
        </div>

          <div className="articles">
            <ArticlesComponent articles={articles}/>
          </div>

          <Footer theme={theme} language={language} langs={langs}/>
      </div>
  )

}

export default Blog