import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom'
import './Assets/Css/App.css'
import { BiLoaderAlt } from "react-icons/bi"
import Navbar from './Components/Partials/Navbar';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Blog from './Components/Blog';
import Admin from './Components/Admin';
import { useEffect, useState } from 'react';
import langs from './langs';
import Main from './Components/Main';
import Contact from './Components/Contact';
import Mentions from './Components/Mentions';

const api = 'https://unovates-api.inovaperf.me/'
const App = ({config}) => {


  const [theme, setTheme] = useState('dark')
  const [language, setLanguage] = useState('fr')
  const [mainLoading, setMainLoading] = useState(true)


 
  useEffect(() => {
    console.log(localStorage)
   if(['en', 'fr'].includes(localStorage.getItem('language'))) {
     setLanguage(localStorage.getItem('language'))
   }
   langs.actualLang = language;


   if(localStorage.getItem('theme') === "light") {
     setTheme('light')
    document.documentElement.setAttribute('theme', 'light')
   }
   document.documentElement.setAttribute('theme', theme)


  
   setTimeout(() => {
    setMainLoading(false)
    document.querySelector('.mainApp.mainContentLoad').classList.add('loaded')
    document.body.style.overflowY = 'auto';
   }, 200)
   
  })
/*
  const partners = [
    {
      image: "/Partners/elkir.png",
      name: "Elkir"
    },
    {
      image: "/Partners/myloot.png",
      name: "MyLOOT"
    },
    {
      image: "/Partners/rlss.png",
      name: "Rl Sideswipe"
    },
    {
      image: "/Partners/evan.png",
      name: "Evan"
    },
    {
      image: "/Partners/Yeikzy.png",
      name: "Yeikzy"
    },
  ]
  */

  const NavbarWithOptions = () => {
    return <Navbar theme={theme} setTheme={setTheme} language={language} setLanguage={setLanguage} langs={langs}/>
  }

  return ( 
      <div className='mainApp'>

        {
          mainLoading ?
          <div className='loadingApp'>
            <BiLoaderAlt/>
          </div>
          : 
          null
        }
        <div className="mainApp mainContentLoad">
          <BrowserRouter>
          <Routes>
  
  <Route path='/' element={<><NavbarWithOptions/><Main langs={langs} theme={theme} language={language} api={api}></Main></>}/>
  <Route path='/about-us' element={<><NavbarWithOptions/><AboutUs langs={langs} theme={theme} language={language} api={api}/></>}/>
  <Route path='/services' element={<><NavbarWithOptions/><Services langs={langs} theme={theme} language={language} api={api}/></>}/>
  <Route path='/blog' element={<><NavbarWithOptions/><Blog langs={langs} theme={theme} language={language} api={api}/></>}/>
  <Route path='/contact' element={<><NavbarWithOptions/><Contact langs={langs} theme={theme} language={language} api={api}/></>}/>
  <Route path='/mentions' element={<><NavbarWithOptions/><Mentions langs={langs} theme={theme} language={language} api={api}/></>}/>
  <Route path='/dash/:category?/:id?' element={<><Admin/></>}/>
  <Route path='/dashboard/:category?/:id?' element={<><Admin/></>}/>
  <Route path='/discord' element={<><NavbarWithOptions/><Navigate to={config.discord}/></>}/>
  
  </Routes>
  </BrowserRouter>
  </div>
     
      </div>
  )

}

export default App
