import '../Assets/Css/Components/Admin.css'

const Admin = () => {

  return ( 
      <div className='ContentApp AdminApp'>
        Admin
      </div>
  )

}

export default Admin