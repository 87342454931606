module.exports = {
    navbar: {
    navLinks: [
    {
        name: {
            fr: "A propos de Nous",
            en: "About Us"
        },
        link: "/about-us"
    },
    {
        name: {
            fr: "Nos Services",
            en: "Our Services"
        },
        link: "/services"
    },
    {
        name: {
            fr: "Blog",
            en: "Blog"
        },
        link: "/blog"
    },
    {
        name: {
            fr: "Contact",
            en: "Contact"
        },
        link: "/contact"
    },
    ],
    discoverBtn: {fr: "Découvrir", en: "Discover"}
    },
    copyright: {fr: "© 2023 Unovates - Tous droits réservés.", en: "© 2023 Unovates - All rights reserved."},
    footerLinks: [
        {
            name: {fr: "À Propos", en : "About Us"},
            links: [
                {
                    name: {fr: "Unovates", en: "Unovates"},
                    url: "/"
                },
                {
                    name: {fr: "Nos produits", en: "Our products"},
                    url: "/products"
                },
                {
                    name: {fr: "Nos partenaires", en: "Our partners"},
                    url: "/partners"
                },
            ]
        },
        {
            name: {fr: "Services", en : "Services"},
            links: [
                {
                    name: {fr: "Gestion", en: "Management"},
                    url: "/services"
                },
                {
                    name: {fr: "Graphisme", en: "Graphics"},
                    url: "/services"
                },
                {
                    name: {fr: "Développement", en: "Development"},
                    url: "/services"
                },
            ]
        },
        {
            name: {fr: "Blog", en : "Blog"},
            links: [
                {
                    name: {fr: "Découvrir", en: "Discover"},
                    url: "/blog"
                },
                {
                    name: {fr: "Nouveautés", en: "News"},
                    url: "/blog"
                },
            ]
        },
        {
            name: {fr: "Legal", en : "Legal"},
            links: [
                {
                    name: {fr: "Mentions légales", en: "Legal notices"},
                    url: "/mentions"
                },
                {
                    name: {fr: "Cookies", en: "Cookies"},
                    url: "/mentions"
                },
            ]
        }
    ],
    headerApp: {
        discover: {fr: "Découvrir", en: 'Discover'},
        h3Text: {fr: "Unovates - Unis pour innover", en: "Unovates - United to innovate"},
        h1Text: {fr: "Une alliance de technologie de sécurité et d’expertise.", en: "An alliance of security technology and expertise."},
        subscribe: {fr: "Abonnez vous à nos newsletters", en: "Subscribe to our newsletters"}
    },
    productsApp: {
        descs: {
            noover: {fr: "Robot discord permettant de trouver des équipiers pour vos projets et de proposer vos services.", en: "Discord bot allowing you to find teammates for your projects and offer your services."},
            pylabs: {fr: "Robot discord permettant de trouver des équipiers pour vos projets et de proposer vos services.", en: "Discord bot allowing you to find teammates for your projects and offer your services."},
        }
    },
    mainPage: {
        partnerApp:{
            trustTitle: {fr: "Ils nous font confiance", en: "They trust us"}
        },
        aboutApp: {
            solutionTitle: {fr: "Une solution globale", en: "A global solution"},
            descriptions: {
                h3First: {fr: "Groupe Unovates", en: "Unovates Group"},
                descFirst: {
                fr: " Unovates est un groupe de jeune talents motivés unis dans le but de proposer leurs services au entreprises et aux particuliers. D'une autre part, Unovates conçoivent des produits et outils innovant dans le but de satisfaire la communauté. ",
                en: "Unovates is a group of young, motivated talents united with the aim of offering their services to businesses and individuals. On the other hand, Unovates design innovative products and tools with the aim of satisfying the community."
                },
                h3Second: {fr: "Nos Produits", en: "Our Products"},
                descSecond: {
                fr: "Unovates proposent leurs services de design graphique, de développement informatique et de gestion de réseaux sociaux au entreprises et au particuliers de manière bénévole",
                en: "Unovates offer their graphic design, IT development and social network management services to businesses and individuals on a voluntary basis."
                },
            }
        },
        productsApp: {
            h3Text: {fr: "Produits d'Unovates", en: "Unovates Products"},
            h1Text: {fr: "Nos Produits", en: "Our Products"},
            btnText: {fr: "Bientôt", en: "Soon"}
        },
        feedBacksApp: {
            title: {fr: "Retours", en: "Backs"},
            secondTitle: {fr: "Les avis clients", en: "Customers feedbacks"},
            whyNot: {fr: "Pourquoi pas vous ?", en: "Why not you ?"},
        }
    },
    aboutUs: {
        hdrTitle: {fr: "Unis pour concevoir nos projets innovants et guider nos clients vers le succès.", en: "United to design our innovative projects and guide our clients towards success."}, 
        hdrDesc: {fr: "Unovates, une solution globale", en: "Unovates, a global solution"},
        productsApp: {
            h1Text: {fr: "Découvrez nos produits", en: "Discover our products"},
        },
        staffApp: {
            h1Text: {fr: "Notre équipe", en: "Our team"},
            h3Text: {fr: "Découvrez les membres de l'équipe Unovates.", en: "Meet the members of the Unovates team."},
        }
    },
    servicesApp: {
        h1Text: {fr: "Toutes nos compétences entièrement déployées pour vos projets.", en: "All our skills fully deployed for your projects."},
        h3Text: {fr: "Nous mettons tout en oeuvre pour satisfaire nos clients et les guider vers le succès.", en: "We do everything we can to satisfy our customers and guide them towards success."},
        buttonText: {fr: "Nous contacter", en: "Contact us"},
        servicesPart: {
            h1Text: {fr: "Services d'Unovates", en: "Unovates Services"},
            h3Text: {fr: "Notre gamme de services", en: "Our range of services"},
            design: {fr: "Design Graphique", en: "Graphic Design"},
            developement: {fr: "Développement IT", en: "Developement IT"},
            cManage: {fr: "Gestion de réseaux sociaux", en: "Social media management"}
        },
        creatorsPart: {
            h1Text: {fr: "Les Créateurs", en: "The creators"},
            h3Text: {fr: "Ces créateurs nous ont fait confiance.", en: "These creators trusted us."},
        }
    },
    blogsApp: {
        h1Text: {fr: "Notre Blog", en: "Our Blog"},
        h2Text: {fr: "Restez informez sur l’actualité Unovates.", en: "Stay informed on Unovates news."}
    },
    contactApp: {
        h1Text: {fr: "Nous contacter", en: "Contact Us"},
        name: {fr: "Nom", en: "Name"},
        firstname: {fr: "Prénom", en: "First name"},
        email: {fr: "E-Mail", en: "E-Mail"},
        request: {fr: "Demande", en: "Request"},
        send: {fr: "Envoyer", en: "Send"},
        cookies: {fr: "Acceptez nos", en: "Accept our"},
        cookiesObligation: {fr: "Veuillez accepter nos cookies.", en: "Please accept our cookies."},
    }
    
}
