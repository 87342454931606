import '../../Assets/Css/Partials/Footer.css'
import FooterBack from "../../Assets/Images/footer.png"
import { Link } from 'react-router-dom'
import {ReactComponent as Logo} from '../../Assets/Images/Branding/logo.svg'
import {ReactComponent as PurpleBack} from '../../Assets/Images/Utils/footerPurple.svg'
import { useEffect } from 'react'
import { FaDiscord, FaInstagram, FaTwitch, FaTwitter } from "react-icons/fa";

const Footer = ({theme, language, langs}) => {



  return ( 


    <div className="footer">
      
      <div className='footerApp'>


<div className="content">



            <div className="left">
              {
                theme === "dark" ?
                <Logo/>
                :
                <Logo/>
              }
            </div>


            <div className="right">


              {
                langs.footerLinks.map(link => {
                  return (
                    <div className="parts">
                      <h1 className="pTlt">
                        {link.name[language]}
                      </h1>

                      <ul className="pList">
                        {
                        link.links.map(lk => {
                          return (
                            <Link to={lk.url}>{lk.name[language]}</Link>
                          )
                        })
                        }
                      </ul>
                    </div>
                  )
                })
              }
              
            




            </div>


            </div>

            <div className="credits">
            {langs.copyright[language]}
            </div>

          <div className="socials">
            <div className="scl">
            <FaInstagram />
            </div>
            <div className="scl">
            <FaTwitter />
            </div>
            <div className="scl">
            <FaDiscord />
            </div>
            
          </div>
          
      </div>
        
      
    </div>
  )

}

export default Footer