import { useEffect, useState } from 'react'
import '../Assets/Css/Components/Mentions.css'
import axios from "axios"
import moment from "moment"
const Mentions = ({api, langs, language, theme}) => {

  
  const [mentions, setMentions] = useState({contentFr: "", contentEn: "", edited: Date.now()})

  function formatDate(timestamp) {
    const aujourdhui = moment();
    const date = moment(timestamp).locale('de');
    console.log(date)
  
    // Vérifier si c'est aujourd'hui
    if (date.isSame(aujourdhui, 'day')) {
      return `${language === "fr" ? "aujourd'hui": "today"}`
    }
  
    // Vérifier si c'est hier
    if (date.isSame(aujourdhui.clone().subtract(1, 'day'), 'day')) {
      return `${language === "fr" ? "hier": "yesterday"}`
    } 
    
  
    // Si ce n'est ni aujourd'hui ni hier, formatter en "le JJ mois"
    return `${language === "fr" ? "Le": ""} ${date.format("DD/MM")}`;
  }

  async function fetchCgu() {
    let res = await axios({
      method: "get",
      withCredentials: true,
      url: api+"api/cgu",
    })

    console.log(res)
    if(res.data.status !== 202) return;
    setMentions(res.data.cgu)
  }

  useEffect(() => {
    fetchCgu()
  }, [])

  return ( 
      <div className='ContentApp MentionsApp'>
        <p>
           {language === "fr" ?
        mentions.contentFr
        :
        mentions.contentEn        
        }
        </p>
        <div className="edited">
          {language === "fr" ? `Modifié ${formatDate(mentions.edited)}` :  `Edited ${formatDate(mentions.edited)}`}
        </div>
       
      </div>
  )

}

export default Mentions