import { Link } from 'react-router-dom'
import '../Assets/Css/Components/MainApp.css'
import { FaPlay } from 'react-icons/fa'
import {ReactComponent as RightArrow} from '../Assets/Icons/rightArrow.svg'
import {ReactComponent as Cloud} from '../Assets/Icons/cloud.svg'
import {ReactComponent as Bolt} from '../Assets/Icons/bolt.svg'
import { useEffect, useRef, useState } from 'react'
import LonniPreview from "../Assets/Images/Partners/LonniPreview.png"
import EvanPreview from "../Assets/Images/Partners/EvanPreview.png"
import NoaPreview from "../Assets/Images/Partners/NoaPreview.jpg"
import NooverPreview from "../Assets/Images/Products/noover.png"
import PyLabsPreview from "../Assets/Images/Products/pylabs.png"
import Footer from './Partials/Footer'
import axios from "axios"


const Main = ({langs, language, theme, api}) => {


  const partners = [
    {
      name: "Elkir",
      max: true,
      logo: {d: require('../Assets/Images/Partners/White/elkir.png'), w: require('../Assets/Images/Partners/Dark/elkir.png')}
    },
    {
      name: "MyLoot",
      max: true,
      logo:  {d: require('../Assets/Images/Partners/White/myloot.png'), w: require('../Assets/Images/Partners/Dark/myloot.png')}
    },
    {
      name: "Rl Sideswipe",
      max: false,
      logo:  {d: require('../Assets/Images/Partners/White/rlss.png'), w: require('../Assets/Images/Partners/Dark/rlss.png')}
    },
    {
      name: "Evan",
      max: false,
      logo:  {d: require('../Assets/Images/Partners/White/evan.png'), w: require('../Assets/Images/Partners/Dark/evan.png')}
    },
    {
      name: "Lonni",
      max: false,
      logo:  {d: require('../Assets/Images/Partners/White/lonni.png'), w: require('../Assets/Images/Partners/Dark/lonni.png')}
    },
    {
      name: "Noa Dorian",
      max: false,
      logo:  {d: require('../Assets/Images/Partners/White/noa.png'), w: require('../Assets/Images/Partners/Dark/noa.png')}
    }
  ]
    

    const [hdrBtnDegree, setHdrBtnDegree] = useState(1);
    const hdrBtnDegreeRef = useRef(hdrBtnDegree);

    const [allPartners, setPartners] = useState([])

    async function fetchAllPartners() {
      let res = await axios({
        method: "get",
        withCredentials: true,
        url: api+"api/partners",
      })
  
      console.log(res)
      if(res.data.status !== 202) return;
      setPartners(res.data.allPartners)
    }
  
    useEffect(() => {
      fetchAllPartners()
    }, [])
  
    useEffect(() => {
      const element = document.querySelector('.hdsBtnFirst');
      let maxDegree = 360;
  
      const updateDegree = () => {
        hdrBtnDegreeRef.current += 1;
        if (hdrBtnDegreeRef.current > maxDegree) {
          hdrBtnDegreeRef.current = 1;
        }
        setHdrBtnDegree(hdrBtnDegreeRef.current);
      };
  
      const intervalId = setInterval(updateDegree, 50);
  
      return () => {
        clearInterval(intervalId);
      };
    }, []);

    
  function validateEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

    

        //  console.log(currentDegree)
         /* element.style.background = `linear-gradient(${currentDegree}deg, 
            var(--headerButtonGradentBorderColor) var(--headerButtonGradentBorderColorPercent),
            var(--headerButtonGradentBorderColor2) var(--headerButtonGradentBorderColorPercent2))`; */
      
  return ( 
      <div className='ContentApp MainApp'>
        
        <div className="hds">

            <div className="textsAndButtons">

                <div className="hdsTexts">
                <h3>
                {langs.headerApp.h3Text[language]}
                </h3>
                <h1>
                {langs.headerApp.h1Text[language]}
                </h1>
                </div>
             
                <div className="hdsButtons">
                    <div className="hdsBtn hdsBtnFirst"
                    style={{
                        background: `linear-gradient(${hdrBtnDegree}deg, 
                            var(--headerButtonGradentBorderColor) var(--headerButtonGradentBorderColorPercent),
                            var(--headerButtonGradentBorderColor2) var(--headerButtonGradentBorderColorPercent2))`
                    }}
                    >
                    <div className="hdsBtnContent">
                        <Link>
                        {langs.headerApp.discover[language]}
                        </Link>
                        </div>
                    </div>
                    <div className="hdsBtn">
                        <div className="hdsBtnContent">
                        <input type="email" placeholder={langs.headerApp.subscribe[language]} /> 
                        <RightArrow onClick={(e) => {
                            let input = e.target.parentNode.children[0]

                            if(!validateEmail(input.value)) {
                                input.classList.add('errorAnimation')
                                setTimeout(() => {
                                    input.classList.remove('errorAnimation')
                                }, 600)
                            } else return alert('to do')
                        }}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="hdsVideo">
            <div className="vdoApp">
             <video className='vdo' onCanPlay={() => {
                 document.getElementById('video').style.transition = 'opacity 1s'; // Durée de la transition en secondes
                 document.getElementById('video').style.opacity = '1';
             }} id="video" src={
                language === "fr"?
                "https://cdn.discordapp.com/attachments/1098608581005287536/1169634672024756364/TRAILER_2_1_4.mov?ex=65561e2c&is=6543a92c&hm=76afafc4413c4fe6ed02ed04366814dadebaf4219f5a286c873620d00f36e3db&"
                :
                "https://cdn.discordapp.com/attachments/1098608581005287536/1143551901854601226/TRAILER_2_1_1.mp4"} controls={false}></video>
               <div className="playBtn" onClick={(e) => {
                const video = document.getElementById('video');
                video.controls = true;
                e.target.closest('.playBtn').style.display = "none"
                 if (video.paused || video.ended) {
                  video.play();
                } else {
                  video.pause();
                }
               }}>
                <FaPlay/>
              </div>
              </div>
            </div>
        </div>


        {
        ///////////////////////// End Of Header
        }

        <div className="partners">
          <h1>
          {langs.mainPage.partnerApp.trustTitle[language]}
          </h1>


          <div className="pList">
            {
              allPartners.map(p => {
                return (
                  <div className="pContent">
                    <img  src={theme === "dark" ? p.whiteLogoLink: p.blackLogoLink} alt="" />
                  </div>
                )
              })
            }
          </div>
        </div>

        {
        ///////////////////////// End Of Partners
        }

        <div className="aboutPart">
          <h1>
          {langs.mainPage.aboutApp.solutionTitle[language]}
          </h1>


          <div className="abtContent">

            <div className="abtDescs">
              <div class="abtDsContent">
                <h3>
                  <Cloud/>   {langs.mainPage.aboutApp.descriptions.h3First[language]}
                </h3>
                <p>
                {langs.mainPage.aboutApp.descriptions.descFirst[language]}
                </p>
              </div>
              <div class="abtDsContent">
                <h3>
                  <Bolt/>  {langs.mainPage.aboutApp.descriptions.h3Second[language]}
                </h3>
                <p>
                {langs.mainPage.aboutApp.descriptions.descSecond[language]}
                </p>
              </div>
            </div>


            <div className="abtImgs">
              {
                allPartners.slice(0, 3).map(p => {
                  return (
                    <div className="abtImgsContent">
                    <img src={p.imageLink} alt="" />
                  </div>
                  )
                })
              }
            </div>

          </div>
        </div>


        {
        ///////////////////////// End Of About
        }

        <div className="products">
          <div>
            <h3>
          {langs.mainPage.productsApp.h3Text[language]}
          </h3>
          <h1>
          {langs.mainPage.productsApp.h1Text[language]}
          </h1>
          </div>

          <div className="prdList">

            <div className="prdMain">
              <div className="prdContent">
                <div className="prdPreview">
                  <img src={NooverPreview} alt="Noover"/>
                </div>
                <div className="prdTxts">
                <div className="prdDscs">
                  <h1>
                    Noover
                  </h1>
                  <div className="prdGoTo">
                   <Link>
                   {langs.mainPage.productsApp.btnText[language]}
                   </Link>
                  </div>
                </div>
                </div>
                
              </div>
            </div>


          </div>
    
        </div>
        
        {
        ///////////////////////// End Of Products
        }

        <div className="fdbSepMain">
        <div className="fdbSep">
          <div className="fdbSepTexts">
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
            <h1>{langs.mainPage.feedBacksApp.title[language]}</h1>
          </div>
        </div>
        </div>


        <div className="fdbApp">
          <h1>
          {langs.mainPage.feedBacksApp.secondTitle[language]}
          </h1>

          <div className="fdbList">

            <div className="fdbCol">
               <div className="fdbMain">
                <div className="fdbContent">
                {
                    allPartners[0] ?
                    <>
                    <div className="top">
                     <img src={allPartners[0]?.avatarLink} alt="" />
                     <h1>
                    {allPartners[0]?.name}
                  </h1>
                    </div>
                    <p className="fcbContentText">
                    {language === "fr" ? allPartners[0]?.feedBackFr : allPartners[0]?.feedBackEn}
                    </p>
                    </>
                    :
                    null
                  }
                </div>
               </div>
            </div>

            <div className="fdbCol">

               <div className="fdbMain fdbMin">
                <div className="fdbContent">
                  {
                    allPartners[1] ?
                    <>
                    <div className="top">
                     <img src={allPartners[1]?.avatarLink} alt="" />
                     <h1>
                    {allPartners[1]?.name}
                  </h1>
                    </div>
                    <p className="fcbContentText">
                    {language === "fr" ? allPartners[1]?.feedBackFr : allPartners[1]?.feedBackEn}
                    </p>
                    </>
                    :
                    null
                  }
                </div>
               </div>

               <div className="fdbMain fdbMin fdbCenter">
               <div className="top">
                     <img src={theme == "dark" ? require("../Assets/Images/purpleGradent.png"): require("../Assets/Images/whiteGradent.png")} alt="Unovates" />
                  <h3>
                  
            {langs.mainPage.feedBacksApp.whyNot[language]}
                  </h3>
                    </div>
                 
               </div>


               <div className="fdbMain fdbMin">
                <div className="fdbContent">
                {
                    allPartners[2] ?
                    <>
                    <div className="top">
                     <img src={allPartners[2]?.avatarLink} alt="" />
                     <h1>
                    {allPartners[2]?.name}
                  </h1>
                    </div>
                    <p className="fcbContentText">
                    {language === "fr" ? allPartners[2]?.feedBackFr : allPartners[2]?.feedBackEn}
                    </p>
                    </>
                    :
                    null
                  }
                 
                </div>
               </div>

            </div>

            <div className="fdbCol">
               <div className="fdbMain">
                <div className="fdbContent">
                {
                    allPartners[3] ?
                    <>
                    <div className="top">
                     <img src={allPartners[3]?.avatarLink} alt="" />
                     <h1>
                    {allPartners[3]?.name}
                  </h1>
                    </div>
                    <p className="fcbContentText">
                    {language === "fr" ? allPartners[3]?.feedBackFr : allPartners[3]?.feedBackEn}
                    </p>
                    </>
                    :
                    null
                  }
                 
                </div>
               </div>
            </div>

          </div>
        </div>

        
      
       {
       // <div style={{height: "500px"}}></div>
      }


        <Footer theme={theme} language={language} langs={langs}/>

      </div>
  )

}

export default Main