import React from 'react';
import ReactDOM from 'react-dom/client';
import './Assets/Css/Main.css'


import App from './App';



const config = {
  discord: "https://discord.gg/dZ55jFUFBy"
}

const root = ReactDOM.createRoot(document.getElementById('mainApp'));
root.render(
      <React.StrictMode>
      <App config={config}/>
      </React.StrictMode>
);

