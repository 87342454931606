import '../Assets/Css/Components/AboutUs.css'
import {ReactComponent as Cloud} from '../Assets/Icons/cloud.svg'
import {ReactComponent as Bolt} from '../Assets/Icons/bolt.svg'
import AboutPreview from '../Assets/Images/Branding/aboutUs.png'
import NooverPreview from "../Assets/Images/Products/noover.png"
import PyLabsPreview from "../Assets/Images/Products/pylabs.png"
import { Link } from 'react-router-dom'
import Footer from './Partials/Footer'
import { useEffect, useState } from 'react'
import axios from "axios"
const AboutUs = ({langs, language, theme, api}) => {


  const [staffs, setStaffs] = useState([])

  async function fetchAllStaff() {
    let res = await axios({
      method: "get",
      withCredentials: true,
      url: api+"api/staff",
    })

    console.log(res)
    if(res.data.status !== 202) return;
    setStaffs(res.data.allStaff)
  }

  useEffect(() => {
    fetchAllStaff()
  }, [])


  return ( 
      <div className='ContentApp AboutUsApp'>
        
        <div className="aboutHdr">
          <h1 className="aboutBgTitle">
          {langs.aboutUs.hdrTitle[language]}
          </h1>


          <div className="aboutSecond">
            <div className="aboutTexts">
              <h2>{langs.aboutUs.hdrDesc[language]}</h2>
              <div className="abtTxts">
                <div className="abtTxt">
                  <h3>
                    <Cloud/> {langs.mainPage.aboutApp.descriptions.h3First[language]}
                  </h3>
                  <p>
                  {langs.mainPage.aboutApp.descriptions.descFirst[language]}
                  </p>
                </div>
                <div className="abtTxt">
                  <h3>
                    <Bolt/> {langs.mainPage.aboutApp.descriptions.h3Second[language]}
                  </h3>
                  <p>
                  {langs.mainPage.aboutApp.descriptions.descSecond[language]}
                  </p>
                </div>
              </div>
            </div>
            <div className="aboutPreview">
              <img src={AboutPreview} alt="Unovates" />
            </div>
          </div>
        </div>



        <div className="staffAbt">

          <div className="staffAbtTop">
            <h1>
            {langs.aboutUs.staffApp.h1Text[language]}
            </h1>
            <h3>
            {langs.aboutUs.staffApp.h3Text[language]}
            </h3>
          </div>


          <div className="staffList">

            {
              staffs.map(stf => {
                return (
                  <div className="stf">
                    <div className="stfPreview">
                      <img src={stf.avatarLink} alt="" />
                    </div>
                    <div className="stfDesc">
                      <h1>
                        {stf.name}
                      </h1>
                      <h2>
                        {stf.status}
                      </h2>
                    </div>
                  </div>
                )
              })
            }

          </div>

        </div>




        <div className="products">
          <div>
            <h3>
          {langs.mainPage.productsApp.h3Text[language]}
          </h3>
          <h1>
          {langs.aboutUs.productsApp.h1Text[language]}
          </h1>
          </div>

          <div className="prdList">

            <div className="prdMain abtUs">
              <div className="prdContent">
                <div className="prdPreview">
                  <img src={NooverPreview} alt="Noover"/>
                </div>
                <div className="prdTxts">
                <div className="prdDscs">
                  <h1>
                    Noover
                  </h1>
                  <p>
                  {langs.productsApp.descs.noover[language]}
                  </p>
                  <div className="prdGoTo">
                   <Link>
                   {langs.mainPage.productsApp.btnText[language]}
                   </Link>
                  </div>
                </div>
                </div>
                
              </div>
            </div>

    

          </div>
    
        </div>

        {
       // <div style={{height: "500px"}}></div>
      }


        <Footer theme={theme} language={language} langs={langs}/>

      </div>
  )

}

export default AboutUs