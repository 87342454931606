import { useState } from 'react'
import '../Assets/Css/Components/Contact.css'
import BackPreview from "../Assets/Images/Branding/contactApp.png"
import Footer from './Partials/Footer'
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from "axios"

const Contact = ({langs, language, theme, api}) => {


    const [cookieChecked, setCookieChecked] = useState(false)
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        email: '',
        content: '',
      });

      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSend = async() => {
        let cookiesAlert = document.querySelector('.obligate')

        const { lastname, firstname, email, content } = formData;
        if (!lastname || !firstname || !email || !content) {
          alert('Veuillez remplir tous les champs requis.');
        }

        if(!cookieChecked) return cookiesAlert.style.opacity = "1"
        else cookiesAlert.style.opacity = "0"

        const res = await axios.post(api+"api/sendmail", formData);

        console.log(res)
      };

  return ( 
      <div className='ContentApp ContactApp'>

      <div className="contactTop">
        <h1>
        {langs.contactApp.h1Text[language]}
        </h1>
      </div>


      <div className="ctApp">
        <div className="ctBack">
        <img src={BackPreview} alt="" />
        </div>
        <div className="ctContent">
        <div className="inpts">
          <div className="names">
            <div className="inpt">
              <h3>{langs.contactApp.name[language]}</h3>
              <input
                type="text"
                name="lastname"
                placeholder=""
                id="name"
                value={formData.lastname}
                onChange={handleInputChange}
              />
            </div>
            <div className="inpt">
              <h3>{langs.contactApp.firstname[language]}</h3>
              <input
                type="text"
                name="firstname"
                id="lastname"
                placeholder=""
                value={formData.firstname}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="inpt">
            <h3>{langs.contactApp.email[language]}</h3>
            <input
              type="text"
              name="email"
              id="email"
              placeholder=""
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="inpt">
            <h3>{langs.contactApp.request[language]}</h3>
            <textarea
              type="text"
              name="content"
              id="content"
              placeholder=""
              value={formData.content}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>

            <div className="agree"> 
                <div className="checkbox" onClick={() => {
                    setCookieChecked((previousState => !previousState))
                }}>
                   {cookieChecked ? <FaCheck /> : <FaCheck opacity={0} />}
                </div>
                <h3>
                {langs.contactApp.cookies[language]} <Link to="/mentions">Cookies</Link>

                    <p className="obligate">
                    {langs.contactApp.cookiesObligation[language]}
                    </p>
                </h3>
            </div>

            <div className="send" onClick={handleSend}>
            {langs.contactApp.send[language]}
            </div>
          
        </div>
      </div>

            
      {
       // <div style={{height: "500px"}}></div>
      }


        <Footer theme={theme} language={language} langs={langs}/>


      </div>
  )

}

export default Contact