import '../../Assets/Css/Partials/Navbar.css'
import {Link} from "react-router-dom"
import { useState } from 'react'

import {FiChevronDown, FiMenu, FiX} from "react-icons/fi"

import {ReactComponent as IconNight} from "../../Assets/Icons/night.svg"
import {ReactComponent as IconDay} from "../../Assets/Icons/day.svg"
import {ReactComponent as DarkLogo} from '../../Assets/Images/Branding/darkLogo.svg'
import {ReactComponent as Logo} from '../../Assets/Images/Branding/logo.svg'

import FlagFr from '../../Assets/Images/Flags/fr.png'
import FlagEn from '../../Assets/Images/Flags/en.png'

const Links = [
    {
        name: "A propos de Nous",
        link: "/about-us"
    },
    {
        name: "Nos Services",
        link: "/services"
    },
    {
        name: "Blog",
        link: "/blog"
    },
    {
        name: "Contact",
        link: "/contact"
    },
]

const Navbar = ({setTheme, theme, setLanguage, language, langs}) => {


    const [opn, setOpn] = useState(false)

    //console.log(langs)

    
    const handleNavBtn = () => {
        document.querySelector('.nav').classList.toggle('open')
        if(document.querySelector('.nav').classList.contains('open')) setOpn(true)
        else setOpn(false)
    }


    const LinksDiv = () => {
        return (
            <>
            <ul className='linksList'>
                {
                    langs.navbar.navLinks.map(lks => {
                        return (
                            <li>
                                <Link to={lks.link} onClick={() => {
                                handleNavBtn()
                            }}>{lks.name[language]}</Link>
                            </li>
                        )
                    })
                }
            </ul>
        </>
        )
    }

    return ( 
        <div className="navApp">
        <div className="nav">


            <div className="logoAndRes">
            <div className="navLogo">
                <Link to="/">
                {theme==="dark"?
                  <Logo/>
                  :
                  <DarkLogo/>}
                </Link>
            </div>
            <div className="resDrop">
                            <div className="resBtn" onClick={() => {
                                handleNavBtn()
                            }}>
                                {opn?
                                <FiX/>
                                :
                                <FiMenu/>
                               }
                            </div>
                        </div>
            </div>

            <div className="navMainLinks">

         

            <div className="left navFlex">
            
           
            <div className="linksFlex">
            <LinksDiv/>
            </div>
            
                
            </div>


            <div className="right navFlex">
           

            <div className="linksFlex">
                <ul>
                    <li >
                        {theme==="dark"?
                          <IconNight 
                          onClick={() => {
                             setTheme(theme === "dark" ? "light" : "dark")
                             localStorage.setItem('theme', theme === "dark" ? "light" : "dark")
                          }}
                          className='themeSwitcher' fill="var(--navTheme)"/>
                        :
                        <IconDay
                        onClick={() => {
                           setTheme(theme === "dark" ? "light" : "dark")
                           localStorage.setItem('theme', theme === "dark" ? "light" : "dark")
                        }}
                        className='themeSwitcher' fill="var(--navTheme)"/>
                        }
                         
                    </li>


                    <li>
                        <div className="langDrop">
                            <div className="langLabel" onClick={() => {
                                console.log(language)

                                let newLang;

                                if(language === "fr") newLang = "en"
                                else newLang = "fr"
                                localStorage.setItem('language', newLang)
                                setLanguage(newLang)
                            }}>
                                {
                                    language === "fr" ?
                                    <img src={FlagFr} alt="Langue"/>
                                    :
                                    <img src={FlagEn} alt="Langue"/>
                                }
                                
                            </div>
                        </div>
                    </li>


                    <li>
                        <Link className="navDiscoverBtn">
                           {langs.navbar.discoverBtn[language]}
                        </Link>
                    </li>
                    
                </ul>
           
            </div>
            </div>

            </div>         
        </div>
      {/*  <div className="resLinks">
              <LinksDiv />
    </div>*/}
      
        </div>
        
    )

}

export default Navbar